<template>
  <div class="templateItemContainer">
    <div
      class="template-card-wrapper"
      :class="{
        'template-border-highlight': isSelected,
        'disabled-template': isDisabled,
      }"
      @click="onTemplateSelect"
      v-if="!isDisabled"
    >
      <div class="template-name-block">
        <p class="template-name-block__name">
          {{ template.name }}
        </p>
      </div>
      <v-row>
        <v-col>
          <v-img
            loading="lazy"
            :lazy-src="'/assets/img/comply/template1.png'"
            :aspect-ratio="16 / 9"
            :src="template.thumbnailURL"
            contain
            alt
          />
        </v-col>
        <v-col>
          <v-img
            loading="lazy"
            :lazy-src="'/assets/img/comply/template1.png'"
            :aspect-ratio="16 / 9"
            :src="getSampleSlideUrl"
            alt
            contain
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-start align-center slide-size-info-container">
        <p class="slide-size-info-container__heading">
          <span v-if="isSlideTypeAvailable()">
            Slide size : {{ template.slide_label }}
          </span>
          <span v-else>Slide size</span>
        </p>
      </div>
    </div>
    <v-tooltip
      attach
      absolute
      max-width="200"
      :content-class="'center-tooltip'"
      v-else
    >
      <template v-slot:activator="{ on }">
        <div
          v-on="on"
          class="template-card-wrapper"
          :class="{
            'template-border-highlight': isSelected,
            'disabled-template': isDisabled,
          }"
        >
          <div class="template-name-block">
            <p class="template-name-block__name">
              {{ template.name }}
            </p>
          </div>
          <v-row>
            <v-col>
              <v-img
                loading="lazy"
                :lazy-src="'/assets/img/comply/template1.png'"
                :aspect-ratio="16 / 9"
                :src="template.thumbnailURL"
                contain
                alt
              />
            </v-col>
            <v-col>
              <v-img
                loading="lazy"
                :lazy-src="'/assets/img/comply/template1.png'"
                :aspect-ratio="16 / 9"
                :src="getSampleSlideUrl"
                alt
                contain
              />
            </v-col>
          </v-row>
          <div
            class="d-flex justify-start align-center slide-size-info-container"
          >
            <p class="slide-size-info-container__heading">Slide size</p>
          </div>
        </div>
      </template>
      <span>{{ $t('common.comingSoon') }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: 'TemplateItem',
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    handleSelectTemplate: {
      type: Function,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
    },
  },
  computed: {
    getSampleSlideUrl() {
      return this.template.sampleSlide
        ? this.template.sampleSlideURL
        : this.template.thumbnailURL;
    },
  },
  data() {
    return {};
  },
  methods: {
    onTemplateSelect() {
      this.handleSelectTemplate(this.template);
    },
    isSlideTypeAvailable() {
      return (
        Object.keys(this.template).includes('slide_type') &&
        this.template.slide_type !== null
      );
    },
  },
};
</script>
<style scoped lang="scss">
.center-tooltip {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
.templateItemContainer {
  position: relative;
  .template-border-highlight {
    border: 1.5px solid var(--new-palette-secondary-secondary-50, #21a7e0);
  }
  .template-card-wrapper {
    display: flex;
    padding: 13.111px;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    flex-shrink: 0;
    cursor: pointer;

    border-radius: 8.396px;
    background: var(--color-styles-old-white, #fff);

    /* Elevation/02 */
    box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.1),
      1px 1px 4px 0px rgba(0, 0, 0, 0.1);

    &.disabled-template {
      opacity: 0.4;
      cursor: default;

      .template-name-block__name {
        color: #a2a9b0;
      }

      &:hover {
        box-shadow: -1px -1px 24px 0px rgba(0, 0, 0, 0.1),
          1px 1px 24px 0px rgba(0, 0, 0, 0.1);
      }
    }

    .template-name-block {
      display: flex;
      align-items: flex-start;
      gap: 4.198px;
      flex: 1 0 0;
      align-self: stretch;
      &__name {
        color: var(--color-styles-old-dark, #212121);
        font-family: Lato;
        font-size: 15.297px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.153px;
        margin-bottom: 0;
      }
    }
  }
}

.template-img-block {
  display: flex;
  width: 311.365px;
  justify-content: space-between;
  align-items: center;
}

.slide-size-info-container {
  margin-top: 8px;
  &__text {
    font-family: Lato;
    font-size: 15.3px;
    font-weight: 400;
    line-height: 18.36px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  &__heading {
    margin-bottom: 0;
  }
}
</style>
