<template>
  <div
    class="change-layout-container"
    :data-pendo-id="isRedesign ? 'redesign-change-layout' : null"
  >
    <div
      class="heading"
      :style="{
        marginBottom:
          origin === 'Synthesis' || origin === 'Redesign' ? '8px' : '24px',
      }"
    >
      {{ $t('generate.smartLayout') }}
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <div class="sub-heading">{{ $t('generate.slideWithDifferentLayout') }}</div>
    <slot>
      <div class="icon-container" v-if="relatedSlides.length">
        <div @click="handleView(false)">
          <v-icon
            :color="isColumnView ? '#000' : '#21A7E0'"
            v-tooltip="{
              content: $t('prezentationGallery.gridView'),
              placement: 'top-center',
            }"
            >{{
              isColumnView ? 'mdi-view-grid-outline' : 'mdi-view-grid'
            }}</v-icon
          >
        </div>

        <div @click="handleView(true)">
          <v-icon
            :color="isColumnView ? '#21A7E0' : '#000'"
            v-tooltip="{
              content: $t('prezentationGallery.columnView'),
              placement: 'top-center',
            }"
            >{{
              isColumnView ? 'mdi-view-agenda' : 'mdi-view-agenda-outline'
            }}</v-icon
          >
        </div>
      </div>
      <div class="layout-content">
        <div
          v-for="(slide, index) in relatedSlides"
          :class="[{ 'column-view': isColumnView }, 'slide']"
          :key="index"
        >
          <v-img
            contain
            lazy-src="/assets/img/slides/placeholder-slide.svg"
            :aspect-ratio="16 / 9"
            :class="{
              current:
                selectedSlideID &&
                (slide.unique_id === selectedSlideID ||
                  slide.id === selectedSlideID),
              disabled: slide.disabled,
            }"
            :src="getImgPath(slide)"
            loading="lazy"
            class="layout-image"
            @click="handleChangeLayout(slide)"
          />
        </div>
      </div>
    </slot>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { trackCommonEvents } from '../../common/Analytics/CommonEvents';
import {
  TD_COLUMN_VIEW,
  TD_GRID_VIEW,
  TD_VIEW_SELECTED,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'ChangeLayout',
  components: {},
  props: {
    relatedSlides: {
      type: Array,
      default: () => [],
    },
    selectedSlideID: {
      type: String,
    },
    origin: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleChangeLayout(slide) {
      this.$emit('change', { type: 'slide_change', slide });
    },
    handleView(value) {
      this.isColumnView = value;
      const otherData = {
        [TD_COMMON_COLUMN_NAME]: TD_VIEW_SELECTED,
        [TD_COMMON_COLUMN_VALUE]: this.isColumnView
          ? TD_COLUMN_VIEW
          : TD_GRID_VIEW,
      };
      trackCommonEvents.trackSmartLayoutViewChange(
        this.currentUser,
        otherData,
        this.origin,
      );
    },
    getImgPath(slide) {
      let path = '';
      if (slide && slide.landscapePath && this.isColumnView) {
        path = slide.landscapePath;
      } else if (slide && slide.thumbnailPath && !this.isColumnView) {
        path = slide.thumbnailPath;
      } else {
        path = '/assets/img/slides/placeholder-slide.svg';
      }
      return path;
    },
  },
  data() {
    return {
      isColumnView: false,
    };
  },
  mounted() {},
  computed: {
    ...mapState('users', ['currentUser']),
    isRedesign() {
      return this.$route.path === '/home/redesign';
    },
  },
};
</script>
<style lang="scss" scoped>
.change-layout-container {
  padding: 20px 20px;
  padding-right: 5px;
  height: calc(100% - 100px);

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 16px;
  }
  .icon-container {
    display: flex;
    margin-right: 30px;
    column-gap: 10px;
    justify-content: flex-end;
    margin-bottom: 20px;

    div {
      cursor: pointer;
    }
  }

  .layout-content {
    overflow: auto;
    height: calc(100% - 70px);
    padding: 5px;
    .slide {
      width: calc(50% - 20px);
      height: 120px;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;
      .layout-image {
        filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1))
          drop-shadow(-1px -1px 4px rgba(0, 0, 0, 0.1));
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;

        &.current {
          border: 1.5px solid #21a7e0;
          cursor: default;
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: default;
        }

        &:hover {
          box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }
    .column-view {
      width: calc(100% - 20px);
      height: auto;
    }
  }
}
.error-layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .no-layout-text {
    text-align: center;
    margin-top: 16px;
    width: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }
}
</style>
