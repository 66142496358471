<template>
  <div class="slides-list" :class="{ 'panel-open': rightPanelComponent }">
    <div class="list-header">
      Slides
      <span class="slide-badge">{{ getUserSelectedSlidesList.length }}</span>
    </div>
    <div class="slides-list__area">
      <template v-if="complySlidesDetails.length !== 0 && !isActionLoading">
        <div
          v-for="(slideObj, index) in complySlidesDetails"
          :key="index"
          :class="`slide-element idea-${index}-dragarea--auto`"
        >
          <div>{{ index + 1 }}</div>
          <div
            class="slide-small-thumbnail-container"
            :class="{
              'selected-slide': selectedImageIndex === index,
              'ml-2': complySlidesDetails.length >= 10 && index + 1 < 10,
            }"
          >
            <v-card hover rounded="lg" class="slide-landscape">
              <v-img
                :aspect-ratio="16 / 9"
                @click="() => selectSlide(index)"
                :src="slideObj.png.thumbnail"
                alt="image"
                contain
              >
              </v-img>
            </v-card>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="example-slide-mini"
          v-for="(slide, index) in getUserSelectedSlidesList"
          :key="index"
        >
          <span>{{ index + 1 }}</span>
          <div class="example-mini-image" v-if="isActionLoading">
            <ClipLoader
              class="loading-spinner"
              :color="'#21a7e0'"
              :width="20"
              :height="20"
            />
          </div>
          <v-img
            v-else
            :aspect-ratio="16 / 9"
            :src="'/assets/img/slides/placeholder-slide.svg'"
            contain
            class="example-mini-image"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      selectedSlideId: null,
      loading: false,
      selectedImageIndex: 0,
      reviewFailedPlaceholder: '/assets/img/comply/compliance-check-failed.svg',
    };
  },
  computed: {
    ...mapState('comply', ['rightPanelComponent', 'slideIndex']),
    ...mapGetters('comply', [
      'complySlidesDetails',
      'getUserSelectedSlidesList',
      'isActionLoading',
      'getCurrentSuggestionView',
    ]),
  },
  watch: {
    slideIndex(val) {
      this.selectSlide(val);
    },
  },
  props: {
    panelHeight: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    ...mapActions('comply', [
      'setReplaceImageSuggestionState',
      'setColorPickerState',
    ]),
    selectSlide(index) {
      if (this.complySlidesDetails && this.complySlidesDetails[index]) {
        this.selectedImageIndex = index;
        this.$store.dispatch('comply/selectSlide', {
          slideData: this.complySlidesDetails[index],
          index: this.selectedImageIndex,
        });
        this.setReplaceImageSuggestionState({
          suggestionNumber: 0,
          current: '',
          suggested: '',
          panelOpen: false,
          color: '',
          suggestedOnLoad: '',
          category: null,
          suggestion: null,
          itemIndex: 0,
          imageFromLib: null,
        });
        if (this.getCurrentSuggestionView !== 'deck') {
          this.setColorPickerState({
            suggestionNumber: 0,
            current: '',
            suggested: '',
            panelOpen: false,
            color: '',
            suggestedOnLoad: '',
            category: null,
            suggestion: null,
            itemIndex: 0,
            imageFromLib: null,
          });
        }
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  background-color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
}

.loader-container {
  width: 100%;
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
.slides-list {
  width: 364px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 5px 10px 10px 0;
  transition: width 0.5s ease-in;
  position: relative;
  top: -5px;

  .list-header {
    display: flex;
    gap: 4px;
    align-items: baseline;
    font-weight: bold;

    .slide-badge {
      background-color: #ffe1b7;
      color: #4d5358;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
    }
  }

  &.panel-open {
    width: 140px;
    transition: width 0.5s ease-in;
  }

  &__area {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100vh - 340px);
    overflow: auto;
    padding-right: 5px;
    padding-top: 5px;
  }

  .example-slide-mini {
    width: 100%;
    display: flex;
    gap: 10px;

    .example-mini-image {
      position: relative;
      aspect-ratio: 16/9;
      width: calc(100% - 20px);
      box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
      border-radius: 12px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.selected-slide {
  border-radius: 2px;
  border: 2px solid #21a7e0;
  box-shadow: -0.8266171813011169px -0.8266171813011169px 3.3064687252044678px 0px
      rgba(0, 0, 0, 0.1),
    0.8266171813011169px 0.8266171813011169px 3.3064687252044678px 0px
      rgba(0, 0, 0, 0.1);
}
.slide-element {
  display: flex;
  gap: 10px;
}

.slide-small-thumbnail-container {
  cursor: pointer;
  box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.1),
    1px 1px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .selected-slide {
    border: 1.5px solid #21a7e0;
  }

  .slide-landscape {
    width: 100%;
  }
}
</style>
