<template>
  <div class="add-slide-container">
    <div class="heading">
      {{
        origin === 'prezentations'
          ? 'Generate slide'
          : $t('generate.addNewSlide')
      }}
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <div class="add-slide-content">
      <div class="textbox-label">
        {{ $t('generate.whatSlideYouLike') }}
        <span class="error--text">(required)</span>
      </div>
      <div class="textarea-prompt-container">
        <v-textarea
          v-model="prompt"
          class="textarea-prompt"
          solo
          :placeholder="
            origin === 'prezentations'
              ? $t('generate.slideExamplePrez')
              : $t('generate.slideExample')
          "
          :rules="instructionRules"
        >
          <template #append>
            <v-btn
              text
              class="clear-button"
              rounded
              v-if="prompt.length"
              color="#20a7e0"
              height="30"
              @click="prompt = ''"
              >{{ $t('common.clear') }}</v-btn
            >
          </template>
        </v-textarea>
      </div>
      <div class="prompt-link-container" v-if="origin === 'prezentations'">
        Not sure what to type? See
        <span class="link" @click="handlePromptExamples">
          our prompt examples.
        </span>
      </div>
      <div class="position-container">
        <img :src="'/assets/img/generate/slide-pos.svg'" alt="slide-pos-icon" />
        <div class="position-content">
          <div class="textbox-label">{{ $t('generate.slidePosition') }}:</div>
          <v-autocomplete
            class="position-input"
            dense
            :items="items"
            v-model="slidePos"
            hide-details
          ></v-autocomplete>
        </div>
      </div>
      <MultiContentPanels :isFirstGeneration="true" v-model="context" />
    </div>
    <!-- <div class="textbox-label">{{ $t('generate.selectPosition') }}</div> -->
    <div class="action-container">
      <v-btn
        elevation="2"
        rounded
        color="primary"
        class="generate-btn"
        @click="generate"
        :disabled="
          prompt.trim().length === 0 ||
          (!!context.links.length && !context.linkAccess)
        "
      >
        {{ $t('slides.generate') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { trackGenerateEvents } from '../../common/Analytics/GenerateEvents';
import MultiContentPanels from './magic-fill/MultiContentPanels.vue';

import {
  TD_PLACEMENT,
  TD_BELOW_THE_SLIDE,
  TD_SMART_TOOLS,
  TD_NUMBEROFSLIDES,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import EventBus from '../../common/event-bus';

export default {
  name: 'AddNewSlide',
  components: { MultiContentPanels },
  props: {
    slideCount: {
      type: Number,
      default: 0,
    },
    isFromSmartTools: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: '',
    },
    promptDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      prompt: '',
      slidePos: 3,
      items: [],
      context: {
        texts: { text: '' },
        files: [],
        links: [],
        linkAccess: false,
        extract_graph_data: false,
      },
      instructionRules: [
        (v) => {
          if (this.origin === 'prezentations') {
            return false;
          }
          return v.length < 200 || 'Maximum character limit exceeded';
        },
      ],
    };
  },
  watch: {
    slideCount(val) {
      this.items = Array(val + 1)
        .fill(1)
        .map((x, y) => x + y);

      this.slidePos =
        this.promptDetails?.slidePos !== null ||
        this.promptDetails?.slidePos !== undefined
          ? this.promptDetails?.slidePos
          : this.items.length;
    },
    promptDetails: {
      handler(val) {
        if (val?.slidePos) {
          this.slidePos = val.slidePos;
        }
        if (val?.prompt) {
          this.prompt = val.prompt;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    generate() {
      this.$emit('change', {
        type: 'add_new_slide',
        slidePos: this.slidePos,
        prompt: this.prompt,
        context: JSON.parse(JSON.stringify(this.context)),
      });
      const otherData = {
        [TD_PLACEMENT]: this.isFromSmartTools
          ? TD_SMART_TOOLS
          : TD_BELOW_THE_SLIDE,
        [TD_NUMBEROFSLIDES]: 1,
      };
      trackGenerateEvents.generateAddASlideGenerate(
        this.currentUser,
        otherData,
      );
    },
    handlePromptExamples() {
      EventBus.$emit('PREZ_PROMPT_EXAMPLES');
    },
  },
  mounted() {
    this.items = Array(this.slideCount + 1)
      .fill(1)
      .map((x, y) => x + y);
    this.slidePos = this.items.length;
  },
};
</script>
<style scoped lang="scss">
.add-slide-container {
  padding: 20px;
  padding-right: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .add-slide-content {
    flex: 1;
    overflow: auto;
    padding-right: 10px;
    padding-left: 3px;
    padding-bottom: 20px;
  }

  .clear-button {
    position: absolute;
    bottom: 5px;
    left: 0;
    margin-left: 8px;
    padding: 5px;
    font-family: 'Lato';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: none;
  }

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
    color: #000000;
  }

  .prompt-link-container {
    padding-bottom: 25px;
    font-size: 16px;

    .link {
      color: #21a7e0;
      cursor: pointer;
    }
  }

  .position-container {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    margin-bottom: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    border-radius: 8px;

    .position-content {
      display: flex;
      align-items: baseline;
      gap: 16px;
    }

    .position-input {
      width: 100px;
    }

    img {
      width: 66px;
      height: 60px;
    }
  }

  .prompt-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #212121;
    margin-bottom: 36px;
  }

  .textbox-label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 16px;
  }
  .textarea-prompt-container {
    position: relative;
    .clear-button-container {
      height: 40px;
      width: 98%;
      background-color: white;
      border-radius: 0px 0px 16px 16px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 3;
      .clear-button {
        text-transform: none;
      }
    }
    .textarea-prompt {
      width: 100%;
      border-radius: 16px;
      ::v-deep .v-input__slot {
        textarea {
          padding-bottom: 40px;
          height: 210px;
          line-height: normal;
        }
      }
    }
    ::v-deep .v-input {
      .v-input__control {
        .v-input__slot {
          textarea::placeholder {
            color: #757575;
          }
          border-radius: 4px;
          padding-bottom: 40px;
          box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
            -2px -2px 4px 0px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .checkbox-container {
    display: flex;
    gap: 35px;
  }

  .generate-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;

    /* Color Styles (Old)/White */

    color: #ffffff;

    background: #21a7e0;
    border-radius: 25px;
  }

  .action-container {
    position: fixed;
    bottom: 0px;
    right: 0;
    width: 550px;
    z-index: 10;
    background: white;
    padding: 10px 24px;
  }
}
</style>
