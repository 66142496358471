<template>
  <div
    class="fingerprint-modal"
    :style="{ padding: `${origin === 'my_prezentation' ? '0px' : '20px'}` }"
  >
    <div class="spinnerContainer" v-if="!loading">
      <v-progress-circular indeterminate :size="50" color="#21a7e0" />
    </div>
    <div v-else>
      <div
        class="title-wrapper"
        :style="{
          marginBottom:
            origin === 'synthesis' || origin === 'redesign' ? '8px' : '25px',
        }"
      >
        <h2>{{ $t('overnightPresentations.audience1') }}</h2>
        <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
      </div>
      <div class="audience-select-title">
        {{ $t('build.step3.tailorSlidesFor') }}
      </div>
      <AudienceSelectorTypesense
        class="audience-selector"
        v-model="filterTailor"
        :placeholder="$t('build.step3.tailorSlidesForPlaceholder')"
        :maxWidth="300"
        @change:user="handleAudienceChange($event)"
        @add="handleAddAudience($event)"
        :origin="origin"
        :extraFields="['question2', 'question3', 'question6']"
      />
      <div class="fingerprint-data-title">
        {{ $t('generate.yourAudienceAtAGlance') }}
      </div>
      <div class="fingerprint-data" v-if="!isPublicLimited">
        <img
          :src="fingerprintData.logo"
          alt="Fingerprint-Logo"
          class="logo-fingerprint"
        />
        <h3 class="fingerprint-heading">
          {{
            $t('generate.fingerprintLabel', {
              audienceLabel: isMeAsAudience
                ? ''
                : $t('overnightPresentations.audience'),
              fingerprint: getFingerprint,
            })
          }}
        </h3>
        <div
          class="pref-item1"
          v-for="(item, index) in fingerprintData.preferences"
          :key="index"
        >
          <div class="pref-item">
            <img :src="item.logo" width="93px" />
            <div class="pref-cont">
              <div class="pref-heading">{{ $t(item.title) }}</div>
              <div class="pref-sub-heading">
                {{ item.preference }}
              </div>
              <div class="pref-data">{{ item.oneLine }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="fingerprint-data" v-else>
        <img
          src="/assets/img/lock-icon@100px.svg"
          alt="Fingerprint-Logo"
          class="logo-fingerprint"
        />
        <h3 class="locked-fingerprint-heading">
          {{
            $t('generate.fingerprintLabel2', {
              audienceLabel: isMeAsAudience
                ? ''
                : $t('overnightPresentations.audience'),
            })
          }}
          <img alt class="locked-inline" src="/assets/img/lock-icon@24px.svg" />
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { fpTypes } from '@/mock/fingerprint';
import AudienceSelectorTypesense from '../../common/AudienceSelectorTypesense.vue';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
import ReminderDialog from '../MyPrezentation/ReminderDialog.vue';
import { capitalizeFirstLetter } from '../../../utils/common';

export default {
  name: 'AudienceMenu',
  props: {
    audience: {
      type: Object,
      default: () => null,
    },
    origin: {
      type: String,
      default: 'generate',
    },
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
    fingerprintResponse: Boolean,
  },
  components: { AudienceSelectorTypesense },
  methods: {
    handleClose() {
      this.$emit('handleClose');
    },

    openRemindModal(currentAudience) {
      this.$forceUpdate();
      this.$modal.show(
        ReminderDialog,
        {
          currentAudience,
          isUser: currentAudience.type === 'user',
          origin: 'slides',
        },
        {
          name: 'ReminderDialog',
          width: '649px',
          height: '80%',
        },
      );
    },
    handleAddAudience(value) {
      if (value && !value.fingerPrint) {
        this.openRemindModal(value);
      } else {
        this.$modal.show(
          AddCollegueDetail,
          {
            origin: this.origin,
          },
          AddNewAudienceModalProps,
        );
      }
    },
    handleAudienceChange(val) {
      let event = {
        id: val.id,
        num_id: val.num_id,
        type: val.audienceType,
        ownerID: val.ownerID,
        email: val.email,
        // Using fingerprint of audience wherever needed
        fingerPrint: val.fingerPrint,
        // Using fingerprint response of audience wherever needed
        fingerprintResponse: [
          `question0,${val.question1}`,
          `question1,${val.question2}`,
          `question2,${val.question3}`,
          `question3,${val.question4}`,
          `question4,${val.question5}`,
          `question5,${val.question6}`,
        ],
      };
      if (this.origin === 'synthesis') {
        event = {
          ...event,
          dataPreference: val.dataPreference,
        };
      }

      if (this.fingerprintResponse) {
        event = {
          ...event,
          has_image: 1,
          color_type: val.question2 === 'leftImage' ? 'high' : 'low',
          has_cartoon: val.question3 === 'leftImage' ? 0 : 1,
          has_tables: val.question6 === 'leftImage' ? 1 : 0,
          has_graphs: val.question6 === 'rightImage' ? 1 : 0,
        };
      }
      if (this.origin === 'my_prezentation') {
        const prezentationData = {
          id: this.prezentationData.id,
          audienceID: this.filterTailor.email,
          audienceOwnerID: this.filterTailor.ownerID
            ? this.filterTailor.ownerID
            : null,
          fingerprintUsed: this.filterTailor.fingerPrint,
        };
        this.updatePrezentation(prezentationData, null, true);
      } else {
        this.$emit('change', {
          type: 'audience_change',
          val: event,
        });
      }
    },
  },
  data() {
    return {
      loading: true,
      searchTerm: '',
      filterTailor: null,
    };
  },
  mounted() {
    const audienceId =
      this.audience && this.audience.id !== this.currentUser.user.id
        ? `${this.audience.id}-${this.audience.type}`
        : 'SELF';
    this.filterTailor = { defaultAudience: audienceId };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('prezentationDetails', ['prezentationData']),
    isPublicLimited() {
      return this.filterTailor?.isPublicLimited;
    },
    fingerprintData() {
      const fpData = fpTypes.find(
        (fprint) =>
          fprint.title === this.filterTailor?.fingerPrint?.toLowerCase(),
      );
      if (!fpData) {
        return fpTypes?.[0];
      }
      return fpData;
    },
    isMeAsAudience() {
      return this.currentUser?.user?.id === this.filterTailor?.id;
    },
    getFingerprint() {
      const fingerprint = this.isMeAsAudience
        ? this.currentUser.user?.fingerPrint
        : this.filterTailor?.fingerPrint;
      return fingerprint ? capitalizeFirstLetter(fingerprint) : '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.fingerprint-modal {
  background: $white-default;
  padding-right: 5px;
  min-width: 382px;
  min-height: 600px;
  overflow: scroll;
  .spinnerContainer {
    display: flex;
    justify-content: center;
    margin: 200px auto;
  }
  .audience-select-title {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .title-wrapper {
    font-weight: 700;
    line-height: 24px;
    // margin-bottom: 25px;
    // position: relative;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }
  .fingerprint-data-title {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 20px 0;
  }
  .fingerprint-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    .fingerprint-heading {
      width: 354px;
      height: 19px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #000000;
    }
    .locked-fingerprint-heading {
      height: 19px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #000000;
      display: flex;
      gap: 5px;
    }
    .logo-fingerprint {
      height: 72px;
      width: 72px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      margin: 20px;
    }
    .pref-item1 {
      padding: 0px;
      height: 80px;
      margin: 20px 0;
      width: 90%;
      .pref-item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        .pref-heading {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          letter-spacing: 0.01em;

          /* Color Styles (Old)/Grey 01 */

          color: #7b7b7b;
        }
        .pref-sub-heading {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;

          /* Color Styles (Old)/Dark */

          color: #212121;
        }
        .pref-data {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          letter-spacing: 0.01em;

          /* Color Styles (Old)/Dark */

          color: #212121;
        }
        .pref-cont {
          width: 60%;
        }
      }
    }
  }
}
</style>
