<template>
  <div>
    <div class="comply-score-main">
      <div class="heading-block">
        <div class="heading-block__main">Brand Compliance suggestions</div>
        <div class="heading-block__sub">Powered by ASTRID™ AI</div>
      </div>
      <div class="suggest-block">
        <p
          class="suggest-block__summary-text"
          v-if="!getDeckFullComplianceInfo && getComplianceScore !== 100"
        >
          We have {{ getSuggestionCount }} suggestions based on brand guidelines
          for your selected template.
        </p>
        <p class="suggest-block__summary-text" v-else>
          We have 0 suggestions based on brand guidelines for your selected
          template.
        </p>
      </div>
      <template v-if="!getDeckFullComplianceInfo && getComplianceScore !== 100">
        <div class="suggest-block-score-section">
          <v-progress-circular
            class="score-circular"
            :rotate="-90"
            :size="150"
            :width="20"
            :color="complianceColor"
            :value="getComplianceScore"
            data-pendo-id="comply-status-score-circular"
          >
            <span class="score">{{ getComplianceScore }}%</span>
          </v-progress-circular>
          <div class="score-summary">
            <p class="mb-0 text-center">
              Your prezentation is
              <span class="score-percentage">{{ getComplianceScore }}%</span>
              compliant with
            </p>
            <p class="mb-0">brand guidelines for your selected template.</p>
          </div>
        </div>
        <div class="cta-block">
          <v-btn
            class="app-button"
            color="primary"
            rounded
            data-pendo-id="comply-make-hundred-compliant-button"
            name--auto="make_100_percent_compliant"
            @click="applyDeckCompliance"
          >
            Make 100% compliant
          </v-btn>
          <v-btn
            text
            rounded
            class="app-button text-btn"
            color="primary"
            name--auto="comply_score_conversion_settings"
            data-pendo-id="comply-conversion-settings-button"
            @click="toggleConversionSettings = !toggleConversionSettings"
          >
            <v-icon>mdi-cog-outline</v-icon> Conversion settings
          </v-btn>
          <div v-if="toggleConversionSettings" style="margin-top: -12px">
            <div :data-pendo-id="getPendoId">
              <v-checkbox
                class="include-image-conversion-check-box"
                v-model="includeImageOrIconConversion"
                :label="iconAndImageConversionLabelText"
                v-if="
                  (isReplaceBrandImageEnabled && allowReplaceImageOverall) ||
                  isReplaceBrandIconsEnabled
                "
              ></v-checkbox>
            </div>
            <div
              data-pendo-id="include-special-colors-conversion"
              class="d-flex justify-space-between align-items-center"
              style="margin-top: -3px"
            >
              <v-checkbox
                class="include-image-conversion-check-box"
                v-model="includeSpecialColorsConversion"
                label="Include special color conversions"
              ></v-checkbox>
              <span>
                <v-tooltip
                  top
                  max-width="350"
                  content-class="tooltip-content"
                  fixed
                  open-delay="500"
                  close-delay="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="18"
                      v-bind="attrs"
                      color="#121618"
                      v-on="on"
                      class="icon info-icon"
                      v-text="`mdi-information-outline`"
                    />
                  </template>
                  <span
                    >Special colors on slides convey meaningful visual cues or
                    emphasize specific elements.</span
                  >
                </v-tooltip>
              </span>
            </div>
          </div>
          <v-btn
            text
            rounded
            outlined
            class="app-button review-btn"
            color="primary"
            data-pendo-id="comply-review-details-button"
            name--auto="comply_score_review_details"
            @click="onReviewDetailsCta"
          >
            Review details
          </v-btn>
        </div>
      </template>
      <v-row v-else>
        <v-col>
          <div class="complied-message">
            <v-img
              max-height="45%"
              src="/assets/img/comply/brand-compliance-achieved.svg"
            ></v-img>
            <div class="complied-message__wrapper">
              <div>
                Congratulations! Your prezentation is
                <span class="score-percentage"> 100% </span>
                compliant with the brand guidelines for your selected template.
              </div>
            </div>
            <div class="complied-message__actions">
              <v-btn
                rounded
                color="primary"
                class="app-button mr-2"
                @click="onDownload"
              >
                Download
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { downloadComplyDeck } from '../../../../utils/api-helper';
// import { downloadFileWithCustomName } from '../../../../utils/common';
// import { getFileURL } from '@/utils/calyrex';
import GenerateActionModal from '../../GenerateSlides/GenerateActionModal.vue';
import { trackComplyEvents } from '../../../common/Analytics/ComplyEvents';
import {
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_DOWNLOAD_CTA,
  TD_PLACEMENT,
  TD_NUMBEROFSLIDES,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_YES,
  TD_UPLAODED_HUNDRED_PERCENT_COMPLIANT_DECK,
  TD_BEFORE_CLICK_ON_REVIEW_DETAILS,
  TD_TEMPLATE_CONVERTER,
  TD_CROSS_FEATURE,
  TD_CHECK_BOXES_SELECTED,
  TD_TIME_SPENT_IN_SECS,
  TD_PREZENTATION_ORIGIN,
  TD_PREZENTATION_COMPANY_SOURCE,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { callMergeSlidesApi } from '../../../../utils/merge-slide-helper';
import { miscConfig } from '../../../../runtimeConfig';
import { ERROR_CODES } from '../utils/comply-helper.utils';

export default {
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('comply', [
      'selectedTemplate',
      'fileDetails',
      'selectedSlides',
      'slidesInfo',
    ]),
    ...mapGetters('comply', [
      'getFileDetails',
      'getComplianceScore',
      'totalSlides',
      'getSuggestionCount',
      'getDeckFullComplianceInfo',
    ]),
    ...mapGetters('users', [
      'isReplaceBrandImageEnabled',
      'allowReplaceImageOverall',
      'isReplaceBrandIconsEnabled',
    ]),
    complianceColor() {
      if (this.getComplianceScore < 50) {
        return '#D32F2F'; // Red for score < 50%
      }
      if (this.getComplianceScore >= 50 && this.getComplianceScore <= 75) {
        return '#FDD835'; // Yellow for score 50% - 75%
      }
      return '#00925A'; // Green for score > 75%
    },
    iconAndImageConversionLabelText() {
      if (this.isReplaceBrandImageEnabled && !this.isReplaceBrandIconsEnabled)
        return 'Include image conversions';
      if (!this.isReplaceBrandImageEnabled && this.isReplaceBrandIconsEnabled)
        return 'Include icon conversions';
      if (this.isReplaceBrandImageEnabled && this.isReplaceBrandIconsEnabled)
        return 'Include image and icon conversions';
      return '';
    },
    getPendoId() {
      if (this.isReplaceBrandImageEnabled && !this.isReplaceBrandIconsEnabled)
        return `include-image-conversion`;
      if (!this.isReplaceBrandImageEnabled && this.isReplaceBrandIconsEnabled)
        return `include-icon-conversion`;
      if (this.isReplaceBrandImageEnabled && this.isReplaceBrandIconsEnabled)
        return `include-image-icon-conversion`;
      return '';
    },
  },
  props: {
    clearSlideLibraryFlags: {
      type: Function,
      require: false,
    },
    isAddedToLibrary: {
      type: Boolean,
      default: false,
    },
    addToLibrary: {
      type: Function,
      default: () => ({}),
    },
    handleClosePopAndSaveToLib: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      // displaySuccessScreen: false,
      downloadOperation: {
        loading: false,
      },
      includeImageOrIconConversion: false,
      processInProgress: false,
      startTime: '',
      toggleConversionSettings: false,
      includeSpecialColorsConversion: false,
    };
  },
  watch: {
    includeImageOrIconConversion: {
      handler(val) {
        this.$store.dispatch('comply/setIncludeImageOrIconConversion', val);
      },
    },
    includeSpecialColorsConversion: {
      handler(val) {
        this.$store.dispatch('comply/setIncludeSpecialColorsInConversion', val);
      },
    },
  },
  methods: {
    ...mapActions('comply', ['applyFullDeckComplianceChanges', 'resetState']),
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      // 'resetAddedToLibrarySnackbarState',
      // 'setPremiumFtLibConfPopupData',
      // 'setIsAddedToLibraryDownload',
    ]),
    onReviewDetailsCta() {
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      this.$emit('reviewDetails', this.getFileDetails.uploadId);
    },
    onDownload() {
      const filename = `${this.getFileDetails.name.replace(
        /.pptx/g,
        '',
      )}-template-converter`;
      const slides = Object.values(this.slidesInfo?.slide_images || {});
      this.$modal.show(
        GenerateActionModal,
        {
          heading:
            this.totalSlides > 1 ? 'Download prezentation' : 'Download slide',
          primaryBtnText: 'Download',
          modalType: 'download',
          primaryBtnAction: this.download,
          type: this.totalSlides > 1 ? 'prezentation' : 'slide',
          operation: this.downloadOperation,
          isAddedToLibrary: this.isAddedToLibrary,
          filename,
          downloadItem:
            this.totalSlides > 1
              ? {
                  name: filename,
                  categories: ['title'],
                  prefs: {
                    // ...this.selectedSlide.slideDetails.prefs,
                    source: 'comply',
                  },
                  filename,
                  noofslides: slides.length,
                  downloadLoaderId: slides[0]?.pptx?.s3_path,
                }
              : {
                  name: `${this.getFileDetails?.name.replace(/.pptx/g, '')}`,
                  prefs: {
                    source: 'comply',
                  },
                  filename: slides[0]?.pptx?.s3_path,
                  noofslides: slides.length,
                  downloadLoaderId: slides[0]?.pptx?.s3_path,
                },
          originPage: 'comply',
          getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
          closePreviousPopups: this.handleClosePopAndSaveToLib,
          logDownload: () => this.logMetricsOnSaveToGoogleDrive(),
        },
        {
          name: 'GenerateActionModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_PLACEMENT]: TD_DOWNLOAD_CTA,
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
        [TD_COMMON_COLUMN_NAME]: TD_UPLAODED_HUNDRED_PERCENT_COMPLIANT_DECK,
        [TD_COMMON_COLUMN_VALUE]: TD_YES,
      };
      trackComplyEvents.complianceCheckerDownload(this.currentUser, otherData);
    },
    async download(name, isFavorite, addToLibraryClick) {
      this.downloadOperation.loading = true;
      //
      const slides = Object.values(this.slidesInfo?.slide_images || {});
      this.setDownloadLoaders({
        loaderId: slides[0]?.pptx?.s3_path,
        downloading: true,
      });
      this.setShowDownloadSnackbar(true);
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: 'tcDownload',
        logDownloadCallback: async () => {
          if (addToLibraryClick === true && !this.isAddedToLibrary) {
            await this.addToLibrary(name, false, 'download');
          }
        },
        downloadStatusCallback: (payload) => {
          this.downloadOperation.loading = false;
          this.setDownloadLoaders({
            loaderId: payload.slideId,
            downloading: false,
          });
          this.$modal.hide('GenerateActionModal');
        },
        metaData: {
          ...this.getFileDetails,
          ...this.slidesInfo,
          fileName: name,
          limitsKey:
            this.slidesInfo?.slide_images?.length > 1
              ? 'prezentation'
              : 'slide',
          origin:
            this.slidesInfo?.slide_images?.length > 1
              ? 'prezentation'
              : 'slide',
          noofslides: this.slidesInfo?.slide_images?.length,
          downloadStatusCallbackPayload: {
            slideId: slides[0]?.pptx?.s3_path,
          },
        },
      });

      // if (addToLibraryClick === true && !this.isAddedToLibrary) {
      //   await this.addToLibrary(name, false, 'download');
      // }
      // const mergedUrl = await this.getMergedUrlForDownload();
      // downloadComplyDeck(this.getFileDetails.uploadId, mergedUrl).then(
      //   async (res) => {
      //     const fileUrl = await getFileURL(
      //       this.currentUser.user.id,
      //       res.data.s3_path,
      //       this.currentUser.userIp,
      //       'IOCUpload',
      //     );
      //     downloadFileWithCustomName(fileUrl, name);
      //     this.downloadOperation.loading = false;
      //     this.$modal.hide('GenerateActionModal');
      //   },
      // );
    },
    async applyDeckCompliance() {
      this.processInProgress = true;
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      const eventStartTime = new Date();
      this.startTime = performance.now();
      try {
        await this.$store.dispatch('comply/makeDeckFullComplinat', true);
        this.$store.dispatch('comply/setIsDeckFullCompliantFlow', true);
        this.processInProgress = false;
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_PLACEMENT]: TD_BEFORE_CLICK_ON_REVIEW_DETAILS,
          [TD_CROSS_FEATURE]: TD_TEMPLATE_CONVERTER,
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStartTime) / 1000,
          [TD_PREZENTATION_ORIGIN]: 'Product',
          [TD_PREZENTATION_COMPANY_SOURCE]:
            this.currentUser?.company?.displayName,
          [TD_COMMON_COLUMN_NAME]: TD_CHECK_BOXES_SELECTED,
          [TD_COMMON_COLUMN_VALUE]: `${
            this.includeImageOrIconConversion ? 'Include image conversions' : ''
          }_${
            this.includeSpecialColorsConversion
              ? 'Include special color conversions'
              : ''
          }`,
        };
        trackComplyEvents.complianceCheckerMakeHundredCompliant(
          this.currentUser,
          otherData,
        );
        // this.displaySuccessScreen = true;
      } catch (error) {
        console.error('Error while processing deck compliance:', error);
        await this.$store.dispatch('comply/resetFileData');
        this.resetState();
        this.$store.dispatch(
          'comply/setTcErrorCode',
          ERROR_CODES.ComplianceCheckFailure,
        );
        this.processInProgress = false;
        // this.$toast.error('Error applying deck compliance changes');
      }
    },

    async logMetricsOnSaveToGoogleDrive() {
      await downloadComplyDeck(this.getFileDetails.uploadId);
    },

    async getMergedUrlForSaveToGdrive() {
      const s = {
        slides: [],
      };
      this.selectedSlides.forEach((slide) => {
        s.slides.push({
          slideId:
            slide?.pptx?.s3_path?.charAt(0) === '/'
              ? slide?.pptx?.s3_path?.substring(1)
              : slide?.pptx?.s3_path,
          isReplaced: false,
          isUploaded: false,
          isGenerated: false,
          isSynthesis: false,
          isRedesign: false,
          isComply: true,
        });
      });
      let mergedUrl = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          if (mergedslide.includes('.pptx')) {
            mergedUrl = mergedslide;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return `${miscConfig.aws_prezentation_distribution}/${mergedUrl}`;
    },

    // async getMergedUrlForDownload() {
    //   console.log();
    //   const s = {
    //     slides: [],
    //     outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
    //     outputPath: `private/template-converter/comply/${this.fileDetails.uploadId}/mergeslides/`,
    //   };
    //   const slides = Object.values(this.slidesInfo?.slide_images || {});
    //   slides.forEach((slide) => {
    //     s.slides.push({
    //       slideId:
    //         slide?.pptx?.s3_path?.charAt(0) === '/'
    //           ? slide?.pptx?.s3_path?.substring(1)
    //           : slide?.pptx?.s3_path,
    //       isReplaced: false,
    //       isUploaded: false,
    //       isGenerated: false,
    //       isSynthesis: false,
    //       isRedesign: false,
    //       isComply: true,
    //     });
    //   });
    //   let mergedUrl = '';
    //   await callMergeSlidesApi(s)
    //     .then((res) => {
    //       const mergedslide = res.data;
    //       if (mergedslide.includes('.pptx')) {
    //         mergedUrl = mergedslide;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    //   return `private/template-converter/comply/${this.fileDetails.uploadId}/mergeslides/${mergedUrl}`;
    // },

    handleVisibility() {
      if (this.processInProgress && document.visibilityState === 'hidden') {
        const endTime = performance.now();
        const timeSpent = Math.round((endTime - this.startTime) / 1000);
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_TIME_SPENT_IN_SECS]: timeSpent,
        };
        trackComplyEvents.complianceCheckerUserTabSwitch(
          this.currentUser,
          otherData,
        );
      }
    },
  },
  mounted() {
    this.$store.dispatch('comply/setIncludeImageOrIconConversion', false);
    document.addEventListener('visibilitychange', this.handleVisibility, false);
  },
  beforeDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibility,
      false,
    );
  },
};
</script>

<style lang="scss" scoped>
.suggest-block-score-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 90px;

  > .score-circular {
    > .v-progress-circular__info .score {
      color: #000;
      text-align: center;
      font-size: 2rem;
      font-weight: 400;
      letter-spacing: 0.14px;
    }
  }
  > .score-summary {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 130%;
  }

  .score-percentage {
    color: #21a7e0;
    font-size: 20px;
    font-weight: 700;
  }
}

.cta-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  padding: 38px;
  text-transform: none;
}
.app-button {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
  letter-spacing: normal;
}

.heading-block {
  &__main {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__sub {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #697077;
    margin-top: 4px;
  }
}

.suggest-block {
  align-self: stretch;
  &__summary-text {
    color: #212121;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    margin-bottom: 0;
  }
}

.comply-score-main {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.complied-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 40px;
  justify-content: center;
  padding-top: 64px;
  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 14px;
    max-width: 70%;
    text-align: center;
  }
  &__description {
    font-weight: 600;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .score-percentage {
    color: #21a7e0;
    font-size: 20px;
    font-weight: 700;
  }
}
::v-deep .v-input--checkbox > .v-input__control > .v-input__slot {
  label {
    color: black;
  }
}
.include-image-conversion-check-box {
  margin-top: 0;
  height: 37px !important;
}
.info-icon {
  margin-left: 9px;
  margin-top: 8px;
}
.review-btn {
  color: var(--PrezentBlue, #21a7e0);

  /* Field Name */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
  letter-spacing: normal;
  border-color: #21a7e0 !important;
  margin-top: 20px;
}
</style>
