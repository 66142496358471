<template>
  <div class="acc-contact-wrapper">
    <v-dialog width="60%" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <div class="acc-contact-btn">
          <v-btn
            class="primary-button"
            color="#21a7e0"
            rounded
            height="48"
            style="text-transform: none !important"
            v-bind="attrs"
            v-on="on"
            @click="onClickGetInTouch"
          >
            {{ $t('acceleratorSteps.stepOneTitle') }}
          </v-btn>
        </div>
      </template>

      <v-card class="acc-contact-cntr" v-if="dialog && !dialogSuccess">
        <div class="acc-contact-header">
          <h2>{{ $t('acceleratorSteps.stepOneTitle') }}</h2>

          <v-icon medium class="notificationMenuCloseIcon" @click="handleClose">
            mdi-close
          </v-icon>
        </div>

        <div class="acc-offerings-cntr">
          <h5>{{ $t('accelerators.whichAcceleratorsAreYouIntrestedIn') }}</h5>
          <ul>
            <li v-for="(offer, index) in offerings" :key="index + '_offerings'">
              <v-radio-group
                row
                v-model="offerType"
                :data-pendo-id="offer.title"
              >
                <v-radio
                  color="#20a7e0"
                  :label="$t(offer.title)"
                  :value="offer.title"
                >
                </v-radio>
              </v-radio-group>
            </li>
          </ul>

          <h5>{{ $t('accelerators.provideDetails') }}</h5>
          <textarea
            :placeholder="$t('accelerators.tellUsMore')"
            v-model="offerDetails"
          ></textarea>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="acc-contact-submit"
            :disabled="offerDetails.length < 10 || !this.offerType"
            @click="handleSubmit()"
          >
            {{ $t('common.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="acc-msg-cntr acc-success-cntr" v-if="dialogSuccess">
        <div class="acc-msg-header">
          <v-icon medium class="notificationMenuCloseIcon" @click="handleClose">
            mdi-close
          </v-icon>
        </div>

        <v-img
          class="acc-succ-img"
          src="/assets/img/accelerators/get_in_touch.svg"
        />

        <div class="acc-succ-text">
          <p>{{ $t('accelerators.thanksForSubmittingRequest') }}</p>
          <p>{{ $t('accelerators.ourTeamGetBackToYou') }}</p>
        </div>
      </v-card>

      <div class="acc-loader acc-msg-cntr" v-if="loader">
        <LoadSpinner />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import serviceOperationsApi from '../../../API/service-operations';
import usersApi from '../../../API/users-api';
import LoadSpinner from '../../common/LoadSpinner';
import { trackAcceleratorsEvents } from '../../common/Analytics/AcceleratorsEvents';
import {
  TD_PLACEMENT,
  TD_SELECTED_ACCELERATOR_TYPE,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_PREZENTATION_COMPANY_SOURCE,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'AcceleratorContactUs',
  props: {
    placement: {
      type: String,
      default: '',
    },
  },
  created() {
    this.offerType = this.offerings[0].title;
  },
  components: { LoadSpinner },
  data() {
    return {
      dialog: false,
      dialogSuccess: false,
      loader: false,
      offerings: [
        {
          title: 'Leadership Presentation - Story Building',
        },
        {
          title: 'Advisory Boards - Data Storytelling',
        },
        {
          title: 'Sales Meetings - Deck Consolidation',
        },
        {
          title: 'Team Activities - Workshop Planning',
        },
        {
          title: 'Executive Presentation Coaching',
        },
        {
          title: 'Custom',
        },
      ],
      offerType: '',
      offerDetails: '',
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.offerType = this.offerings[0].title;
      }
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    handleClose() {
      this.dialog = false;
      this.dialogSuccess = false;
      this.offerType = '';
      this.offerDetails = '';
    },
    onClickGetInTouch() {
      const otherData = {
        [TD_PLACEMENT]: this.placement,
      };
      trackAcceleratorsEvents.acceleratorsGetInTouchClick(
        this.currentUser,
        otherData,
      );
    },
    async handleSubmit() {
      this.loader = true;
      const currUser = await usersApi.methods.getUserInfo();
      const body = {
        email: currUser.user.id,
        serviceRequestType: this.offerType,
        additionalDetails: this.offerDetails,
        source: 'accelerators',
      };

      const response = await serviceOperationsApi.methods.submitServiceRequest(
        body,
      );

      if (response) {
        this.loader = false;
        this.dialogSuccess = true;
      }

      const otherData = {
        [TD_PLACEMENT]: this.placement,
        [TD_COMMON_COLUMN_NAME]: TD_SELECTED_ACCELERATOR_TYPE,
        [TD_COMMON_COLUMN_VALUE]: this.offerType,
        [TD_PREZENTATION_COMPANY_SOURCE]: this.offerDetails,
      };
      trackAcceleratorsEvents.acceleratorsSubmitClick(
        this.currentUser,
        otherData,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.acc-contact-wrapper {
  ::v-deep .v-card {
    height: 80vh;
    overflow-y: scroll;
  }
}
.acc-contact-cntr {
  padding: 1rem;
  border-radius: 12px !important;
  .acc-contact-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      flex: 1;
    }
  }

  .acc-offerings-cntr {
    h5 {
      margin: 1rem 0;
      font-size: 1rem;
      font-weight: 600;
    }

    ul {
      list-style: none;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 1.5rem;

      li {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 1.5rem 2rem;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 1rem;

        .v-input--selection-controls {
          margin: 0;
          padding: 0;
        }

        ::v-deep .v-label {
          left: 0.5rem !important;
          color: black;
        }

        ::v-deep .v-messages {
          min-height: 0;
        }

        ::v-deep .v-radio {
          margin-right: 0;
        }

        ::v-deep .v-input__slot {
          margin-bottom: 0;
        }
      }
    }

    textarea {
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 1rem;
      width: 100%;
      min-height: 150px;
      font-size: 1rem;
    }
  }

  ::v-deep .v-card__actions {
    padding: 1rem 0 0 0;
  }

  .acc-contact-submit {
    background-color: #21a7e0;
    letter-spacing: normal;
    color: white;
    font-weight: 700;
    font-size: 1rem;
    text-transform: capitalize;
    border-radius: 25px;
    width: 100px;
  }
}

.acc-contact-btn {
  display: grid;
  place-items: center;
  margin: 1rem 0;

  button {
    font-family: Lato;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    letter-spacing: normal;
  }
}

.acc-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background-color: #00000038;
  backdrop-filter: blur(2px);
}

.acc-msg-cntr {
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  h2 {
    margin-bottom: 0.5rem;
  }
}

.acc-success-cntr {
  .acc-succ-img {
    width: 55%;

    ::v-deep .v-image__image--cover {
      background-size: 100%;
    }
  }
}

.acc-msg-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.acc-succ-text {
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 3rem;
}
</style>
