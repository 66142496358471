<template>
  <div class="storyline-text-area">
    <div class="storyline-input-container">
      <div v-if="showBar" class="side-bar"></div>
      <div
        class="storyline-input"
        :class="{ selected: focused && !readOnly, 'error-input': error }"
      >
        <v-lazy v-if="prefix">
          <v-textarea
            :error="error"
            v-model="text"
            hide-details
            no-resize
            rows="1"
            auto-grow
            :readonly="readOnly"
            @focus="focused = true"
            @blur="focused = false"
            @input="textChange"
          >
            <template #prepend v-if="prefix">
              <span>•</span>
            </template></v-textarea
          >
        </v-lazy>
        <v-text-field
          v-else
          :error="error"
          v-model="text"
          :readonly="readOnly"
          @focus="focused = true"
          @blur="focused = false"
          @input="textChange"
          hide-details
        ></v-text-field>
      </div>
    </div>
    <div
      class="hint"
      :class="{
        'hint--exceeded': text.length > maxLength,
        show: focused && !readOnly,
        'hint--bar': showBar,
      }"
    >
      Character limit: {{ text.length }} / {{ maxLength }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StorylineTextarea',
  props: {
    showBar: {
      type: Boolean,
    },
    prefix: {
      type: Boolean,
    },
    maxLength: {
      type: Number,
      default: 26,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.text = val;
      },
    },
  },
  computed: {
    error() {
      return this.text.length === 0 || this.text.length > this.maxLength;
    },
  },
  methods: {
    textChange(val) {
      this.$emit('input', val);
    },
  },
  data() {
    return {
      text: '',
      focused: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.hint {
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  letter-spacing: 0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(52, 58, 63, 1);
  visibility: hidden;
  margin-top: 4px;

  &.show {
    visibility: visible;
  }
}

.hint--exceeded {
  color: #d32f2f;
}

.hint--bar {
  padding-left: 8px;
}

.storyline-input-container {
  display: flex;
  gap: 4px;
  .side-bar {
    width: 2px;
    background: rgba(255, 205, 137, 1);
  }

  .storyline-input {
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    flex: 1;

    ::v-deep .v-input__slot {
      &::before,
      &::after {
        border: none !important;
      }

      textarea,
      input {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        color: #121619;
        padding: 0;

        &:read-only {
          cursor: pointer;
        }
      }
    }

    .v-input {
      margin-top: 0;
      padding-top: 0;
    }

    &.selected {
      border: 1.5px solid rgba(33, 167, 224, 1);

      &.error-input {
        border: 1.5px solid #d32f2f;
      }
    }
  }
}
</style>
