var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"replace-visual"},[_c('div',{staticClass:"replace-visual-title"},[_vm._v(" "+_vm._s(_vm.$t('generate.replaceVisual'))+" "),_c('div',{staticClass:"sub"},[_vm._v(_vm._s(_vm.$t('build.step3.poweredByZenSence')))])]),(_vm.isLoading)?_c('div',{staticClass:"loading-spinner"},[_c('v-progress-circular',{attrs:{"size":50,"width":2,"color":"primary","indeterminate":""}})],1):(_vm.error)?_c('div',{staticClass:"no-image"},[_c('img',{attrs:{"src":'/assets/img/generate/slide-with-no-visuals.svg',"alt":"image","contain":""}}),_c('div',{staticClass:"desc"},[_c('div',[_vm._v(_vm._s(_vm.$t('generate.currentSlideHaveNoVisuals')))]),_c('div',[_vm._v(_vm._s(_vm.$t('generate.toAddVisualUseDifferentLayout')))])]),(_vm.origin === 'prezentations')?_c('v-btn',{staticClass:"primary-btn",attrs:{"elevation":"2","rounded":"","color":"primary"},on:{"click":_vm.changeLayout}},[_vm._v(" "+_vm._s('Change layout')+" ")]):_c('v-btn',{staticClass:"primary-btn",attrs:{"elevation":"2","rounded":"","color":"primary"},on:{"click":_vm.changeLayout}},[_vm._v(" "+_vm._s(_vm.$t('generate.changeLayout'))+" ")])],1):[_c('div',{staticClass:"replace-visual-verbose",style:({
        paddingTop:
          _vm.origin === 'Synthesis' || _vm.origin === 'Redesign' ? '8px' : '16px',
      })},[_vm._v(" "+_vm._s(_vm.$t('replaceImage.selectImageReplace'))+" ")]),(!_vm.isLoading && _vm.filteredImages && _vm.filteredImages.length > 0)?_c('div',{class:("select-image my-1 " + (_vm.isConvertingInProgress || _vm.isLocalImageUploading ? 'item-disabled' : '') + " "),attrs:{"justify":"center","align":"center"}},[_c('v-slide-group',{attrs:{"show-arrows":"always"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.filteredImages),function(item){return _c('v-slide-item',{key:item.replace_ref,class:("" + (item.imageIndex ===
            (_vm.currentSelectedSlideImageData &&
              _vm.currentSelectedSlideImageData.imageIndex)
              ? 'selected'
              : '')),scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var toggle = ref.toggle;
return [(!item.invalid)?_c('v-img',{staticClass:"select-image__item ma-2",attrs:{"max-height":"80","max-width":"100","height":"80","contain":"","src":item.imgUrl,"lazy-src":"/assets/img/slides/placeholder-slide.svg"},on:{"click":function($event){return _vm.onImageSelected(item, toggle)}}}):_vm._e()]}}],null,true)})}),1)],1):_vm._e(),_c('ImageLibrary',_vm._g({key:_vm.imageLibraryComponentKey,attrs:{"extractedImages":_vm.extractedImages,"currentSelectedSlideImageData":_vm.currentSelectedSlideImageData,"isConvertingInProgress":_vm.isConvertingInProgress,"slideData":_vm.selectedSlide,"isGenerate":"","noOfSlides":_vm.noOfSlides,"origin":_vm.origin},on:{"imageSelected":function($event){return _vm.onReplaceImage($event)}}},_vm.$listeners))],_c('ErrorDialog',{attrs:{"show":_vm.isImageFound,"on-primary-click":_vm.onErrorDialogOk,"primary-btn-text":_vm.$t('common.ok'),"text":_vm.$t(_vm.errorMessageLoadingImageReplaceData),"onClose":_vm.onErrorDialogClose}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }