<template>
  <div class="storyline-container">
    <div
      class="heading"
      :style="{
        marginBottom: '24px',
      }"
    >
      Storyline Editor
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <template v-if="processCompleted">
      <div class="sub-heading">
        Customise the storyline of your prezentation. Effortlessly edit any text
        on the slides.
      </div>
      <div class="se-content">
        <draggable
          class="drag-area"
          :list="storylines"
          group="slides"
          v-bind="dragOptions"
          draggable=".drag"
          @update="onUpdate"
        >
          <template v-for="(storyline, ind) in storylines">
            <StorylineItem
              :readOnly="isEditInProgress"
              :storyline="storyline"
              :key="storyline.id"
              :index="ind + 1"
              :selected="ind === selectedIndex"
              @save="storylineEdited"
              @changeSelectedSlide="storylineSelected"
              @edit="edit"
            />
          </template>
        </draggable>
      </div>
      <div class="se-action">
        <v-btn
          elevation="2"
          rounded
          color="primary"
          class="save-btn"
          @click="regenerate()"
          :disabled="!slidesEdited || isEditInProgress"
        >
          {{ $t('generate.regenerate') }}
        </v-btn>
      </div>
    </template>
    <div v-else class="transition-container">
      <img
        src="/assets/img/generate/storyline_loading.png"
        class="transition-img"
      />
      <clip-loader :color="`#21a7e0`" :width="20" :height="20" />
      <div class="transition-message">
        Your storyline will be ready shortly...
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex';
import Vue from 'vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

import draggable from 'vuedraggable';
import StorylineItem from './StorylineItem.vue';

export default {
  name: 'StorylineEditor',
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: '',
    },
  },
  components: { draggable, StorylineItem, ClipLoader },
  data() {
    return {
      storylines: [],
      dragOptions: {
        animation: 900,
        ghostClass: 'ghost-list',
      },
      isEditInProgress: false,
      hasDragCompleted: false,
    };
  },
  computed: {
    selectedIndex() {
      return this.storylines.findIndex((i) => i.id === this.selected);
    },
    processCompleted() {
      return this.slides.every((i) => i.completed);
    },
    slidesEdited() {
      return this.hasDragCompleted || this.storylines.some((i) => i.edited);
    },
  },
  watch: {
    slides(val) {
      this.storylines = val.map((slide) => ({
        ...(({
          title,
          subtitle,
          content,
          // eslint-disable-next-line camelcase
          section_header,
          // eslint-disable-next-line camelcase
          slide_type,
          id,
          completed,
          error,
        }) => ({
          title,
          subtitle,
          content,
          section_header,
          slide_type,
          id,
          completed,
          error,
        }))(slide),
        edited: false,
      }));
    },
  },
  methods: {
    onUpdate() {
      this.hasDragCompleted = true;
    },
    edit(val) {
      this.isEditInProgress = val;
    },
    regenerate() {
      this.$emit('change', {
        type: 'story_content_change',
        storylines: this.storylines,
        needSorting: this.hasDragCompleted,
      });
      this.hasDragCompleted = false;
    },
    storylineEdited({ storyline, index }) {
      console.log(storyline, index);
      Vue.set(this.storylines, index - 1, { ...storyline });
      //   this.storylines[index-1] = storyline;
    },
    storylineSelected(id) {
      this.$emit('changeSelectedSlide', id);
    },
  },
  mounted() {
    this.storylines = this.slides.map((slide) => ({
      ...(({
        title,
        subtitle,
        content,
        // eslint-disable-next-line camelcase
        section_header,
        // eslint-disable-next-line camelcase
        slide_type,
        id,
        // eslint-disable-next-line camelcase
        job_callbak_id,
        completed,
        error,
      }) => ({
        title,
        subtitle,
        content,
        section_header,
        slide_type,
        id,
        job_callbak_id,
        completed,
        error,
      }))(slide),
      edited: false,
    }));
  },
};
</script>
<style lang="scss" scoped>
.storyline-container {
  padding: 20px 20px;
  padding-right: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    //  margin-bottom: 24px;
    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 16px;
  }
  .se-content {
    flex: 1;
    overflow: auto;
    padding: 5px;

    .drag-area {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .se-action {
    .save-btn {
      margin-top: 25px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      gap: 8px;
      height: 32px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: capitalize;
      letter-spacing: normal;
      line-height: 19px;

      /* Color Styles (Old)/White */

      color: #ffffff;

      background: #21a7e0;
      border-radius: 25px;
    }
  }

  .transition-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }

  .transition-img {
    width: 400px;
    height: 350px;
  }

  .transition-message {
    margin-top: 20px;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
  }
}
</style>
