<template>
  <div class="tutorials-wrapper">
    <div v-if="previousRoute === 'search' && isSearchPage">
      <v-btn class="btn-back" @click="goToSearchLandingPage">
        <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
        <span>{{ $t('slides.backToSearch') }}</span>
      </v-btn>
    </div>
    <div class="tutorials-header">{{ $t('generate.tutorials') }}</div>
    <v-text-field
      solo
      v-model="tutorialSearch"
      single-line
      hide-details
      class="item-search-input"
      autocomplete="off"
      :placeholder="
        !!routeQuery
          ? $t('help.displayResults', {
              count: searchResults ? searchResults.length : 0,
            })
          : $t('help.searchTutorials')
      "
    >
      <template v-slot:append>
        <v-icon
          color="primary"
          @click="resetSearch"
          v-text="!!routeQuery || tutorialSearch ? `mdi-close` : `mdi-magnify`"
        />
      </template>
    </v-text-field>
    <v-sheet class="pt-4 pb-10" max-width="100%">
      <v-chip-group
        v-model="selectedCategory"
        show-arrows
        mandatory
        cols="1"
        active-class="primary"
      >
        <v-chip
          color="#21a7e0"
          outlined
          v-for="(keyword, index) in keywords"
          :key="index"
          @click="(e) => onFilterChipClicked(keyword)"
        >
          {{ $t(keyword.name) }}
        </v-chip>
      </v-chip-group>
    </v-sheet>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        :size="68"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div
      class="tutorial-items__wrapper"
      v-if="
        unmodifiedTutorialsList &&
        unmodifiedTutorialsList.length > 0 &&
        tutorials.length
      "
    >
      <v-row style="width: 100%">
        <v-col
          v-for="(item, index) in tutorials"
          :key="index"
          class="d-flex child-flex"
          xs="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
        >
          <TutorialCard
            :tutorials="tutorials"
            :item="item"
            :handleVideoClick="handleVideoOpen"
          />
        </v-col>
      </v-row>
    </div>
    <v-row v-if="!tutorials.length && !loading">
      <EmptyState
        img-url="/assets/img/help/no-results-found-send-support-request.svg"
        :is-btn-visible="true"
        :buttonName="'help.supportRequest'"
        :handleCTA="handleOpenSendRequest"
      >
        <template v-slot:description>
          <div class="no-results__wrapper">
            <p class="no-results-found">
              {{ $t('build.step1.storylineFieldNoResult') }}
            </p>
            <p class="no-results__description">
              {{ $t('help.noResultDescription') }}
            </p>
          </div>
        </template>
      </EmptyState>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { getFileURL } from '@/utils/calyrex';
import EmptyState from '../../../common/EmptyState.vue';
import HelpApi from '../../../../API/help-api';
import { transformId } from '@/utils/common';
import TutorialCard from './TutorialCard.vue';
import LocalSearch from '@/services/localSearch';
import TutorialVideoDetail from './TutorialVideoDetail.vue';
import EventBus from '../../../common/event-bus';
import { AnalyticsHandler } from '../../../common/Analytics/AnalyticsHandler';
import {
  MatomoAnalyticsHandler,
  TUTORIALS,
  VIDEO_MODULE,
  SEARCH_CHIP,
} from '../../../common/Analytics/MatomoAnalyticsHandler';
import { trackSearchEvents } from '../../../common/Analytics/SearchEvents';
import {
  TD_TUTORIALCHIP,
  TD_TUTORIALTITLE,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { logSearchQuery } from '../../../../utils/api-helper';
import { trackHelpEvents } from '../../../common/Analytics/HelpEvents';

export default {
  name: 'Tutorials',
  components: { EmptyState, TutorialCard },
  mixins: [HelpApi],
  data() {
    return {
      previousRoute: '',
      displayVideo: false,
      overlay: false,
      searchResults: null,
      searchEngine: null,
      loading: true,
      tutorialSearch: '',
      selectedCategory: '',
      keywords: [],
      // keywords: [
      //   {
      //     name: 'All',
      //     display_name: 'overnightPresentations.all',
      //     category: 'All',
      //   },
      //   {
      //     name: 'Overview',
      //     display_name: 'helpItems.tutorial_onboarding_lib_chip',
      //     category: 'Overview',
      //   },
      //   {
      //     name: 'Slide Library',
      //     display_name: 'helpItems.tutorial_slide_lib_chip',
      //     category: 'Slide Library',
      //   },
      //   {
      //     name: 'Prezentation Library',
      //     display_name: 'helpItems.tutorial_prez_ex_chip',
      //     category: 'Prezentation Library',
      //   },
      //   {
      //     name: 'Story Builder',
      //     display_name: 'helpItems.tutorial_story_builder_chip',
      //     category: 'Story Builder',
      //   },
      //   {
      //     name: 'Fingerprint',
      //     display_name: 'profile.fingerprint',
      //     category: 'Fingerprint',
      //   },
      //   {
      //     name: 'Synthesis',
      //     display_name: 'profile.synthesis',
      //     category: 'Synthesis',
      //   },
      //   {
      //     name: 'Redesign',
      //     display_name: 'helpItems.tutorial_redesign_chip',
      //     category: 'Redesign',
      //   },
      //   {
      //     name: 'Template Converter',
      //     display_name: 'helpItems.tutorial_template_converter_chip',
      //     category: 'Template Converter',
      //   },
      //   {
      //     name: 'Overnight Prezentations',
      //     display_name: 'overnightPresentations.overnightPresentation',
      //     category: 'Overnight Prezentations',
      //   },
      //   {
      //     name: 'Uploader',
      //     display_name: 'helpItems.tutorial_slide_uploader_chip',
      //     category: 'Uploader',
      //   },
      //   {
      //     name: 'Auto Generator',
      //     display_name: 'helpItems.tutorial_auto_generator_chip',
      //     category: 'Auto Generator',
      //   },
      //   {
      //     name: 'Courses',
      //     display_name: 'helpItems.tutorial_courses_chip',
      //     category: 'Courses',
      //   },
      //   {
      //     name: 'Best Practice Library',
      //     display_name: 'rightSideBar.bestPracticesText',
      //     category: 'Best Practice Library',
      //   },
      //   {
      //     name: 'Profile',
      //     display_name: 'navbarActions.profile',
      //     category: 'Profile',
      //   },
      //   {
      //     name: 'Search',
      //     display_name: 'prezentationGallery.search',
      //     category: 'Search',
      //   },
      //   { name: 'Help', display_name: 'help.help', category: 'Help' },
      //   { name: 'General', display_name: 'help.general', category: 'General' },
      // ],
      unmodifiedTutorialsList: [],
      categoryToTutorialsMap: {},
      filteredResults: [],
      tutorialDialog: {
        isOpen: false,
        item: null,
      },
      featureEnablementConfig: {
        'Overnight Prezentations': 'hasOPAccess',
        'Auto Generator': 'getGenerateAccessLevel',
        Uploader: 'getUploadAccessLevel',
        'Template Converter': 'isComplyFullAccess',
        Synthesis: 'isSynthesisFullAccess',
        Redesign: 'isRedesignFullAccess',
      },
    };
  },
  watch: {
    routeQuery(val) {
      if (val) {
        this.handleGlobalSearch();
      }
    },
    tutorialSearch() {
      this.handleSearch();
    },
    // eslint-disable-next-line func-names
    'currentUser.user.language': async function () {
      this.loading = true;
      const data = await this.getTutorialDetails();
      this.unmodifiedTutorialsList =
        this.filterFeatureEnablementTutorials(data);
      this.searchEngine = new LocalSearch(this.unmodifiedTutorialsList, [
        'title',
        'description',
        'category',
      ]);

      for (const tutorial of this.unmodifiedTutorialsList) {
        for (const categoryStr of tutorial.category) {
          const category = categoryStr.toLowerCase();
          if (this.categoryToTutorialsMap[category]) {
            this.categoryToTutorialsMap[category].push(tutorial);
          } else {
            this.categoryToTutorialsMap[category] = [tutorial];
          }
        }
      }
      console.log('categoryToTutorialsMap', this.categoryToTutorialsMap);
      this.handleCategorySelect();
      this.handleGlobalSearch();
      this.loading = false;
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('search', ['isSearchPage']),
    ...mapGetters('users', [
      'getOPAccessLevel',
      'getUploadAccessLevel',
      'getGenerateAccessLevel',
      'isComplyFullAccess',
      'isSynthesisFullAccess',
      'isRedesignFullAccess',
    ]),
    hasOPAccess() {
      return this.getOPAccessLevel && this.getOPAccessLevel !== 'no_access';
    },
    routeQuery() {
      return this.$route.query.id;
    },
    tutorials() {
      if (this.searchResults && this.routeQuery) {
        return this.searchResults;
      }
      const searchKey = this.tutorialSearch?.trim();
      if (searchKey) {
        // Reset category filter before proceeding with the search
        if (this.selectedCategory !== 'all') {
          [this.selectedCategory] = this.keywords;
          this.handleCategorySelect();
        }
        return this.searchEngine.search(searchKey);
      }
      return this.filteredResults;
    },
  },
  async mounted() {
    this.previousRoute = this.$router.app.previousRoute.name;
    // Hide chips when feature disabled
    Object.keys(this.featureEnablementConfig).forEach((category) => {
      const flagKey = this.featureEnablementConfig[category];
      const isFetaureEnabled = this[flagKey];
      if (!isFetaureEnabled) {
        const index = this.keywords.findIndex((k) => k.category === category);
        this.keywords.splice(index, 1);
      }
    });

    this.loading = true;
    const data = await this.getTutorialDetails();
    this.unmodifiedTutorialsList = this.filterFeatureEnablementTutorials(data);
    this.searchEngine = new LocalSearch(this.unmodifiedTutorialsList, [
      'title',
      'description',
      'category',
    ]);
    await this.getSectionDetails();
    for (const tutorial of this.unmodifiedTutorialsList) {
      for (const categoryStr of tutorial.category) {
        const category = categoryStr.toLowerCase();
        if (this.categoryToTutorialsMap[category]) {
          this.categoryToTutorialsMap[category].push(tutorial);
        } else {
          this.categoryToTutorialsMap[category] = [tutorial];
        }
      }
    }
    console.log('categoryToTutorialsMap', this.categoryToTutorialsMap);
    this.handleCategorySelect();
    this.handleGlobalSearch();
    this.loading = false;
    MatomoAnalyticsHandler.trackHelp(
      {
        type: TUTORIALS.toLowerCase(),
      },
      this.currentUser,
    );

    EventBus.$on('tutorials_click', () => {
      this.resetSearch();
    });
  },
  beforeDestroy() {
    EventBus.$off('tutorials_click');
  },
  methods: {
    // eslint-disable-next-line func-names
    handleSearch: debounce(function () {
      if (this.tutorialSearch) {
        trackHelpEvents.hybridSearch(
          this.currentUser,
          this.tutorialSearch,
          'tutorials',
          this.tutorials?.length,
        );
      }
    }, 500),
    filterFeatureEnablementTutorials(data) {
      return data.filter((tutorial) => {
        const category = tutorial.category.join();
        const featureFlag = this.featureEnablementConfig[category];
        if (featureFlag && !this[featureFlag]) {
          return false;
        }
        return true;
      });
    },
    goToSearchLandingPage() {
      this.$router.back();
    },
    handleGlobalSearch() {
      if (this.$route.query.id) {
        const logPayload = {
          type: 'Help',
          searchTerm: '',
          searchTermResult: false,
        };
        logPayload.searchTerm = this.$route.query.display_name
          ? this.$route.query.display_name.toLowerCase()
          : '';
        const searchItem = this.findTutorialItem(this.$route.query.id);
        if (searchItem) {
          this.searchResults = [searchItem];
          this.handleVideoOpen(searchItem);
        } else {
          this.searchResults = [];
        }
        logPayload.searchTermResult = !!this.searchResults.length;
        if (this.$route.query.log_search) {
          logSearchQuery(logPayload)
            .then()
            .catch((err) => console.log(err));
        }
      }
    },
    handleOpenSendRequest() {
      this.$root.$emit('openHelpMenuRequest');
    },
    handleVideoClosedFn() {
      this.tutorialDialog = { isOpen: false };
      const query = { ...this.$route.query };
      // removing log_search and display_name from query
      if (query.log_search) {
        delete query.log_search;
      }
      if (query.display_name) {
        delete query.display_name;
      }
      this.$router.replace({ query }).catch(() => query);
    },
    handleCategorySelect(categorySelected) {
      if (!categorySelected || categorySelected === 'all') {
        this.filteredResults = this.unmodifiedTutorialsList;
      } else {
        this.filteredResults =
          this.categoryToTutorialsMap[categorySelected.toLowerCase()] || [];
      }
    },

    resetSearch() {
      this.tutorialSearch = '';
      if (this.$route.query.id) {
        this.$router.replace({ query: null });
        this.searchResults = null;
      }
    },

    async getSignedFileURL(uri, type) {
      const formattedUri = type === 'image' ? `public/${uri}` : uri;
      return await getFileURL(
        this.currentUser.username,
        formattedUri,
        this.currentUser.userIp,
        type === 'image' ? 'storage' : '',
      );
    },

    async getTutorialDetails() {
      return await HelpApi.methods
        .getHelpData('tutorial', this.currentUser.user.language)
        .then(
          async (resp) => {
            const decoratedTutorials = resp.data.map(async (tutorial) =>
              this.decorateTutorial(tutorial),
            );
            return await Promise.all(decoratedTutorials);
          },
          (error) => {
            console.log(error.response);
          },
        );
    },

    async getSectionDetails() {
      try {
        const resp = await HelpApi.methods.getHelpSections();
        if (resp?.data) {
          this.keywords = resp.data;
          this.keywords.unshift({
            name: 'All',
            display_name: 'overnightPresentations.all',
            category: 'All',
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    findTutorialItem(id) {
      if (this.unmodifiedTutorialsList.length && id) {
        // we transform the ID because Calyrex returns Ids with _ rather than -
        const foundItem = this.unmodifiedTutorialsList.find(
          (e) =>
            e.uuid.toLowerCase() === transformId(this.$route.query.id) ||
            e.id.toString().toLowerCase() === transformId(this.$route.query.id),
        );
        return foundItem;
      }
      return null;
    },
    async decorateTutorial(tutorial) {
      tutorial.imageUrl = await this.getSignedFileURL(
        tutorial.image_link,
        'image',
      );
      tutorial.videoUrl = await this.getSignedFileURL(
        tutorial.video_link,
        'video',
      );
      return tutorial;
    },
    onFilterChipClicked(keyword) {
      console.log('keyword-', keyword);
      // Reset the search before proceeding with category filter
      this.tutorialSearch = '';
      const chipValue = keyword.name.toLowerCase();
      this.resetSearch();
      this.handleCategorySelect(chipValue);
      MatomoAnalyticsHandler.trackHelp(
        {
          type: TUTORIALS.toLowerCase(),
          tActionItem: SEARCH_CHIP,
          tActionValue: chipValue,
        },
        this.currentUser,
      );
      trackSearchEvents.trackTutorialIdeachipClick(this.currentUser, {
        [TD_TUTORIALCHIP]: keyword.name,
      });
    },
    handleVideoOpen(item) {
      this.tutorialDialog.item = item;
      this.tutorialDialog.isOpen = true;
      this.$modal.show(
        TutorialVideoDetail,
        {
          streamVideoURL: this.tutorialDialog.item.videoUrl,
          modalclosed: this.handleVideoClosedFn,
        },
        {
          name: 'TutorialVideoDetail',
          width: '1280px',
          height: '720px',
        },
      );

      AnalyticsHandler.viewedTutorialVideo(this.currentUser, item.title);
      trackHelpEvents.helpTutorialsClick(this.currentUser, {
        [TD_TUTORIALTITLE]: item.title,
      });
      MatomoAnalyticsHandler.trackHelp(
        {
          type: TUTORIALS.toLowerCase(),
          tActionItem: VIDEO_MODULE,
          tActionValue: item.title,
        },
        this.currentUser,
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';
.tutorials-wrapper {
  padding: 1.5rem 2rem 2rem;

  .btn-back {
    border: none;
    text-transform: none;
    background-color: #ffffff !important;
    box-shadow: none;
    letter-spacing: normal;
    color: #21a7e0;
    padding: 0;
    margin: 0px 0px 0px -8px;
  }

  ::v-deep .v-chip {
    border-radius: 40px;
    font-size: 20px;
    height: 40px;
    margin-right: 18px !important;
    padding: 0 25px !important;

    &.v-chip--active.v-chip--active {
      background: #21a7e0 !important;
      color: white !important;
    }
  }
  ::v-deep {
    .item-search-input {
      align-items: center;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 24px;
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
      display: flex;
      justify-content: flex-start;
      margin-left: 2px;
      padding: 0 !important;
      position: relative;
      width: 425px;
      z-index: 3;

      > .v-input__control {
        min-height: 40px;
        > .v-input__slot {
          box-shadow: none;
        }
      }

      .v-input__control > .v-input__slot:before {
        border-style: none;
      }

      input {
        background-clip: padding-box;
        border: none;
        color: $gray-search-input;
        display: block;
        font-size: 14px;
        line-height: 1.25;
        margin: 0 auto;
        outline: none;
        padding: 0.5rem 0.7rem;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        width: 100%;
      }

      input::placeholder {
        color: $gray-dark-login-text !important;
        font-size: 14px;
        opacity: 1;
      }

      .v-input__append-inner {
        padding-right: 5px;
      }
    }
  }

  .tutorials-header {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 32px;
    text-align: left;
  }

  .tutorial-items__wrapper {
    height: 60vh;
    overflow-y: auto;
    padding: 5px;
    width: 100%;
  }

  .no-results__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;

    .no-results-found {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.01em;
      line-height: 29px;
      text-align: center;
    }
    .no-results__description {
      height: 52px;
      line-height: 130%;
      margin-bottom: -10px !important;
      padding-bottom: 0;
      text-align: center;
      width: 344px;
    }
  }
}
</style>
