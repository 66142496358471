<template>
  <div
    class="storyline-item"
    :class="{
      expand: isExpanded,
      edit: isEditInProgress,
      drag: !isEditInProgress && !readOnly,
      selected: selected,
      'error-item': storyline.error,
    }"
    @click="expand()"
  >
    <div v-if="isEditInProgress" class="edit-panel">
      <span>Edit text on slide</span>
      <div class="edit-actions">
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              :disabled="valueSame || !valid"
              size="24"
              :color="valueSame || !valid ? 'ccc' : '#00925A'"
              name="add-context-icon"
              class="action-btn"
              :ripple="false"
              @click.stop="saveEdit()"
            >
              mdi-check
            </v-icon>
          </template>
          <span>Save</span>
        </v-tooltip>
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="24"
              color="#D32F2F"
              name="add-context-icon"
              class="action-btn"
              :ripple="false"
              @click.stop="closeEdit()"
            >
              mdi-close
            </v-icon>
          </template>
          <span>Dismiss</span>
        </v-tooltip>
      </div>
    </div>
    <div
      class="storyline-main-section"
      :class="{
        'non-editable': storyline.slide_type === 'thank_you' || storyline.error,
      }"
    >
      <v-icon class="storyline-drag"> mdi-drag-vertical </v-icon>
      <div class="storyline-index">{{ padIndex }}</div>
      <div v-if="storyline.error" class="storyline-title-error">
        <v-icon class="err-icon" size="24" :color="'#D32F2F'">
          mdi-alert
        </v-icon>
        <span>Oops something went wrong</span>
      </div>
      <div
        class="storyline-title"
        v-else-if="storyline.slide_type === 'thank_you'"
      >
        Thank you
      </div>
      <div class="storyline-title" v-else>
        <StorylineTextarea
          :readOnly="isReadOnly"
          v-model="storylineObj.title"
          :maxLength="storyline.slide_type === 'cover_slide' ? 80 : 90"
          ref="textareas"
        />
      </div>
      <div class="actions">
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="18"
              color="#000"
              name="add-context-icon"
              class="action-icons"
              :ripple="false"
              @click.stop="edit()"
            >
              mdi-pencil-outline
            </v-icon>
          </template>
          <span>Edit text on slide</span>
        </v-tooltip>
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              size="18"
              color="#000"
              name="add-context-icon"
              :ripple="false"
              class="action-icons"
              @click.stop="expand()"
            >
              {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </template>
          <span>Expand</span>
        </v-tooltip>
      </div>
    </div>
    <div
      class="storyline-sub-section"
      v-if="storyline.slide_type === 'discussion_topic'"
    >
      <template v-for="(header, ind) in storylineObj.content">
        <StorylineTextarea
          :key="'content' + ind"
          showBar
          :readOnly="isReadOnly"
          v-model="storylineObj.content[ind]"
          :maxLength="110"
          ref="textareas"
        />
      </template>
    </div>
    <div
      class="storyline-sub-section"
      v-else-if="storyline.slide_type !== 'thank_you'"
    >
      <StorylineTextarea
        v-if="storylineObj.subtitle !== null"
        showBar
        :readOnly="isReadOnly"
        v-model="storylineObj.subtitle"
        :maxLength="110"
        ref="textareas"
      />
      <template v-for="(header, ind) in storylineObj.section_header">
        <StorylineTextarea
          :key="'header' + ind"
          showBar
          :readOnly="isReadOnly"
          v-model="storylineObj.section_header[ind]"
          :maxLength="50"
          ref="textareas"
        />
        <StorylineTextarea
          :key="'content' + ind"
          prefix
          :readOnly="isReadOnly"
          v-model="storylineObj.content[ind]"
          :maxLength="110"
          ref="textareas"
        />
      </template>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import StorylineTextarea from './StorylineTextarea.vue';

export default {
  name: 'StorylineItem',
  props: {
    storyline: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: { StorylineTextarea },
  computed: {
    padIndex() {
      return this.index.toString().padStart(2, '0');
    },
    valueSame() {
      return _.isEqual(this.storyline, this.storylineObj);
    },
    isReadOnly() {
      return (
        !this.isEditInProgress ||
        (this.readOnly && !this.selected) ||
        this.storyline.slide_type === 'thank_you'
      );
    },
    valid() {
      if (this.$refs.textareas && this.$refs.textareas.length) {
        return this.$refs.textareas
          ? this.$refs.textareas.every((textarea) => !textarea.error)
          : false;
      }
      return this.$refs.textareas ? !this.$refs.textareas.error : false;
    },
  },
  data() {
    return {
      isExpanded: false,
      isEditInProgress: false,
      storylineObj: {},
    };
  },
  methods: {
    expand() {
      if (!this.isEditInProgress && !this.readOnly && !this.storyline.error) {
        if (this.storyline.slide_type !== 'thank_you') {
          this.isExpanded = !this.isExpanded;
        }
        this.$emit('changeSelectedSlide', this.storyline.id);
      }
    },
    edit() {
      this.$emit('edit', true);
      this.$emit('changeSelectedSlide', this.storyline.id);
      this.isExpanded = true;
      this.isEditInProgress = true;
      this.storylineObj = JSON.parse(JSON.stringify(this.storyline));
    },
    closeEdit() {
      this.isEditInProgress = false;
      this.storylineObj = JSON.parse(JSON.stringify(this.storyline));
      this.$emit('edit', false);
    },
    saveEdit() {
      this.isEditInProgress = false;
      this.$emit('save', {
        storyline: {
          ...this.storylineObj,
          edited: true,
        },
        index: this.index,
      });
      this.$emit('edit', false);
    },
  },
  watch: {},
  mounted() {
    this.storylineObj = JSON.parse(JSON.stringify(this.storyline));
  },
};
</script>
<style lang="scss" scoped>
.storyline-item {
  max-height: 48px;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid rgba(221, 225, 230, 1);
  display: flex;
  cursor: pointer;
  overflow: hidden;
  flex-direction: column;
  transition: max-height 0.5s ease;
  background: white;

  &.expand {
    max-height: 1000px;
    transition: max-height 0.5s ease;
  }

  .edit-panel {
    display: flex;
    justify-content: space-between;
    font-family: Lato;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #121619;
    padding: 8px;
    align-items: center;

    .edit-actions {
      display: flex;
      gap: 10px;

      .action-btn {
        padding: 10px;
      }
    }
  }

  &.drag:hover {
    background: rgba(242, 244, 248, 1);
  }

  &.selected {
    border: 1px solid #21a7e0;
  }

  &.error-item {
    border: 1px solid #d32f2f;

    .storyline-title-error {
      color: #d32f2f;
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: 0.01em;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: 10px;
    }
  }

  .storyline-index {
    margin-right: 8px;
    border: 2px solid rgba(255, 205, 137, 1);
    width: 26px;
    height: 26px;
    padding: 5px;
    border-radius: 4px;
    background: rgba(255, 243, 226, 1);
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: rgba(18, 22, 25, 1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .storyline-title {
    flex: 1;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(18, 22, 25, 1);
    position: relative;
    top: 8px;
  }

  .non-editable {
    .storyline-title {
      top: 0;
      padding: 4px 8px 4px 8px;
    }
  }

  .storyline-drag {
    opacity: 0;
    transition: 0.3 all ease;
  }

  .actions {
    display: flex;
    gap: 10px;
    position: absolute;
    right: 10px;
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .storyline-main-section {
    display: flex;
    width: 100%;
    transition: padding-right 0.3s ease;
    position: relative;
    align-items: center;
    position: relative;
    min-height: 40px;
    transition: padding-right 0.3s ease;
    margin-bottom: 10px;
  }

  &.drag {
    .storyline-main-section:hover {
      &:not(.non-editable) {
        padding-right: 60px;
        .actions {
          opacity: 1;
          transform: translateX(0);
        }

        .action-icon {
          background: #007bff;
          color: #fff;
          padding: 5px;
          border-radius: 50%;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          animation: fade-in 0.3s ease forwards;
          animation-delay: var(--delay);
          transform: translateX(50%);
          opacity: 0;
        }

        .action-icon:nth-child(1) {
          --delay: 0s;
        }

        .action-icon:nth-child(2) {
          --delay: 0.1s;
        }

        .action-icon:nth-child(3) {
          --delay: 0.2s;
        }

        @keyframes fade-in {
          to {
            transform: translateX(0);
            opacity: 1;
          }
        }
      }
      .storyline-drag {
        opacity: 1;
        transition: 0.3 all ease;
      }
    }
  }

  .storyline-sub-section {
    padding-left: 54px;
  }

  // &.edit {
  //   &:hover {
  //     background: none;
  //   }
  // }
}
</style>
