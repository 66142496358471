import { render, staticRenderFns } from "./QuickAccessToolbarComponent.vue?vue&type=template&id=68e8d14e&scoped=true&"
import script from "./QuickAccessToolbarComponent.vue?vue&type=script&lang=js&"
export * from "./QuickAccessToolbarComponent.vue?vue&type=script&lang=js&"
import style0 from "./QuickAccessToolbarComponent.vue?vue&type=style&index=0&id=68e8d14e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68e8d14e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VHover,VIcon,VMenu,VTooltip})
