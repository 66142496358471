<template>
  <div>
    <div class="d-flex flex-column">
      <div class="color-box">
        <v-icon
          v-if="shape.icon && color && shape.type !== 'color_highlight'"
          :color="'#' + colorHex"
          size="48"
        >
          {{ shape.icon }}
        </v-icon>
        <v-img
          :src="shape.icon"
          v-else-if="shape && color && shape.type === 'color_highlight'"
          :style="{ backgroundColor: '#' + colorHex }"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="#21a7e0"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-img>
        <v-img
          :src="shape.placeholderIcon"
          v-else-if="shape && !color && shape.type === 'color_highlight'"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="#21a7e0"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-img>
        <v-img
          height="50px"
          width="50px"
          :src="shape.placeholderIcon"
          v-else-if="shape && !color && shape.placeholderIcon"
        >
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular
                color="#21a7e0"
                indeterminate
              ></v-progress-circular>
            </div>
          </template>
        </v-img>
        <div
          v-else-if="shape.type === 'font'"
          class="shape"
          :style="{ color: '#' + colorHex }"
        >
          Abc
        </div>
        <div v-else class="shape" :style="shapeStyle"></div>
      </div>
      <div class="color-details" v-if="!colorDisplayName">
        <span v-if="color">{{
          colorDisplayName ? colorDisplayName : `#${colorHex}`
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const SUGGESTION_NAME_MAPPING = {
  'Fill Color - Shape': {
    type: 'shape_fill',
    placeholderIcon: '/assets/icons/comply/FillColorShape.png',
  },
  'Highlight Color - Shape': {
    type: 'color_highlight',
    icon: '/assets/icons/comply/DefaultIconFontWord.svg',
    placeholderIcon: '/assets/icons/comply/TextMarchingAntsmorelines.svg',
  },
  'Fill Color - Bulletpoints': {
    type: 'bullet_fill',
    icon: 'mdi-format-list-bulleted',
  },
  'Fill Color - Chart': {
    type: 'chart_fill',
    icon: 'mdi-equalizer',
    placeholderIcon: '/assets/icons/comply/FillColorChart.png',
  },
  'Fill Color - Table': {
    type: 'table_fill',
    icon: 'mdi-view-grid',
    placeholderIcon: '/assets/icons/comply/FillColorTable.png',
  },
  'Fill Color - Icon': { type: 'chart_fill', icon: 'mdi-map-marker' },
  'Outline Color - Shape': {
    type: 'shape_outline',
    isOutline: true,
    placeholderIcon: '/assets/icons/comply/OutlineColorShape.png',
  },
  'Outline Color - Table': {
    type: 'table_outline',
    icon: 'mdi-grid-large',
    placeholderIcon: '/assets/icons/comply/OutlineColorTable.png',
  },
  'Outline Color - Icon': {
    type: 'icon_outline',
    icon: 'mdi-map-marker-outline',
  },
  'Outline Color - Chart': {
    type: 'chart_ouline',
    icon: 'mdi-equalizer-outline',
    placeholderIcon: '/assets/icons/comply/OutlineColorChart.png',
  },
  'Font Color - Chart': { type: 'font' },
  'Font Color - Shape': { type: 'font' },
  'Font Color - Table': { type: 'font' },
  'Fill Color - Shape(spl)': {
    type: 'shape_fill',
    placeholderIcon: '/assets/icons/comply/FillColorShape.png',
  },
  'Outline Color - Chart(spl)': {
    type: 'chart_ouline',
    icon: 'mdi-equalizer-outline',
    placeholderIcon: '/assets/icons/comply/OutlineColorChart.png',
  },
  'Outline Color - Table(spl)': {
    type: 'table_outline',
    icon: 'mdi-grid-large',
    placeholderIcon: '/assets/icons/comply/OutlineColorTable.png',
  },
  'Fill Color - Table(spl)': {
    type: 'table_fill',
    icon: 'mdi-view-grid',
    placeholderIcon: '/assets/icons/comply/FillColorTable.png',
  },
  'Outline Color - Shape(spl)': {
    type: 'shape_outline',
    isOutline: true,
    placeholderIcon: '/assets/icons/comply/OutlineColorShape.png',
  },
  'Fill Color - Chart(spl)': {
    type: 'chart_fill',
    icon: 'mdi-equalizer',
    placeholderIcon: '/assets/icons/comply/FillColorChart.png',
  },
  'Highlight Color - Shape(spl)': {
    type: 'color_highlight',
    icon: '/assets/icons/comply/DefaultIconFontWord.svg',
    placeholderIcon: '/assets/icons/comply/TextMarchingAntsmorelines.svg',
  },
};

export default {
  props: {
    color: String,
    suggestionName: String,
    colorDisplayName: {
      type: String,
      default: '',
    },
  },
  computed: {
    colorHex() {
      return this.color ? this.color.substring(1) : '';
    },
    shape() {
      return SUGGESTION_NAME_MAPPING[this.suggestionName];
    },
    shapeStyle() {
      return !this.shape?.isOutline
        ? {
            backgroundColor: `#${this.colorHex}`,
            borderRadius: '50%',
          }
        : {
            borderColor: `#${this.colorHex}`,
            borderWidth: '6px',
            borderStyle: 'solid',
            borderRadius: '50%',
            backgroundColor: 'transparent',
          };
    },
  },
};
</script>

<style lang="scss" scoped>
.color-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: relative;
}

.dashed-border {
  border: 2px dashed;
  border-radius: 50%;
  margin-bottom: 18px;
}

.shape {
  font-size: 32px;
  width: 100%;
  height: 100%;
}

.color-details {
  color: #000;
  padding: 6px 5px;
  text-transform: uppercase;
}

.color-details-with-display-name {
  color: #000;
  padding: 6px 5px;
}
</style>
