import { render, staticRenderFns } from "./StorylineTextarea.vue?vue&type=template&id=07fc6361&scoped=true&"
import script from "./StorylineTextarea.vue?vue&type=script&lang=js&"
export * from "./StorylineTextarea.vue?vue&type=script&lang=js&"
import style0 from "./StorylineTextarea.vue?vue&type=style&index=0&id=07fc6361&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07fc6361",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VLazy,VTextField,VTextarea})
