import { render, staticRenderFns } from "./ChangeLayout.vue?vue&type=template&id=169cd38b&scoped=true&"
import script from "./ChangeLayout.vue?vue&type=script&lang=js&"
export * from "./ChangeLayout.vue?vue&type=script&lang=js&"
import style0 from "./ChangeLayout.vue?vue&type=style&index=0&id=169cd38b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "169cd38b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VIcon,VImg})
